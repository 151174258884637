<template>
    <div class="baseMain">
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openAdd('new')" v-if="authorityStatus.newEditDel.show">新建类别
                </el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="name" label="类别"></el-table-column>
                <!-- <el-table-column prop="projects_count" label="项目数量"></el-table-column> -->
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openAdd('edit',scope.row)" v-if="authorityStatus.newEditDel.show">
                            编辑
                        </el-link>
                        <el-link class="tableButton" type="danger" @click.prevent="openDel(scope.row)"
                                 v-if="authorityStatus.newEditDel.show">
                            删除
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <!--<div class="basePage" style="margin-top: 4px;">-->
        <!--<publicPage :publicPageTotal="total"></publicPage>-->
        <!--</div>-->
        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
                <el-form-item label="类别名称" prop="name">
                    <el-input v-model="ruleForm.name" style="width: 240px;"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    // import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {businessGet, businessPost, businessDel, businessPut} from '../../api/business.js'

    import {utilsGetAuthority} from '../../utils/utils.js'

    export default {
        name: "formType",
        components: {
            // publicPage,
            publicTable
        },
        data() {
            return {
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                total: 0,
                dialogVisible: false,
                dialogTitle: '',
                ruleForm: {
                    name: '',
                    type: 2,
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                selMsg: null,
                authorityStatus: {
                    newEditDel: {
                        method: 'post',
                        url: '/admin/v1/category',
                        show: false,
                    },
                },
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus);
            this.$nextTick(() => {
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.getTableData();
            })
        },
        methods: {
            openDel(val) {
                this.$confirm('此操作将永久删除该条目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/category/' + val.id;
                    businessDel(url, {}).then(res => {
                        setTimeout(() => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.dialogVisible = false;
                                this.getTableData()
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        }, 500)
                    })
                }).catch(() => {

                })
            },
            saveAdd() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/category';
                        let data = {
                            name: this.ruleForm.name,
                            type: this.ruleForm.type,
                            icon: '1',
                        }
                        if (this.dialogTitle == '新建类别') {
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData()
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                        else if (this.dialogTitle == '编辑类别') {
                            url = url + '/' + this.selMsg.id;
                            businessPut(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.dialogVisible = false;
                                    this.getTableData()
                                }
                                else {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'warning'
                                    });
                                }
                            })
                        }
                    }
                })
            },
            openAdd(sort, val) {
                if (sort == 'new') {
                    this.dialogTitle = '新建类别';
                    this.ruleForm.name = '';
                }
                else if (sort == 'edit') {
                    this.dialogTitle = '编辑类别'
                    this.selMsg = val;
                    this.ruleForm.name = this.selMsg.name;
                }
                this.dialogVisible = true;
            },
            getTableData() {
                let url = '/admin/v1/category?type=' + this.ruleForm.type;
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.tableData = res.data.data;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

</style>
